import React from "react";
//@ts-ignore
import CanvasJSReact from "@canvasjs/react-charts";
import Concepts from "lib/data/Concepts";
import Pathways from "lib/data/Pathways";
import Focuses from "lib/data/Focuses";
import { Await, defer, useLoaderData } from "react-router-dom";
import { TErrorRes, TGetWebUsageSummaryRes } from "@sharedTypes/res.type";
import { GetWebUsageSummary } from "api/webUsage/GetWebUsageSummary";
import Loading from "components/LoadingPage";
import constants from "Constants";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export function Loader() {
  const webUsageSummaryPromise = GetWebUsageSummary();
  return defer({ webUsageSummaryPromise });
}

type TWebUsageGraphDatum = {
  x: Date;
  y: number;
};

function DashboardLoadError() {
  return (
    <div style={{ minHeight: window.innerHeight - constants.navHeight }}>
      <p>Error loading dashboard data</p>
    </div>
  );
}

function WebTrafficChart({ webTraffic }: { webTraffic: TWebUsageGraphDatum[] }) {
  const options = {
    animationEnabled: true,
    theme: "light2",

    title: {
      text: "Web Traffic - Total Clicks Per Day",
    },
    axisX: {
      valueFormatString: "DD MMM",
    },
    axisY: {
      title: "Clicks",
    },
    data: [
      {
        type: "line",
        dataPoints: webTraffic,
      },
    ],
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
}

type TViewsPerPageGraphDatum = {
  label: string;
  y: number;
};

function ViewsPerPageChart({ viewsPerPage }: { viewsPerPage: TViewsPerPageGraphDatum[] }) {
  const options = {
    animationEnabled: true,
    theme: "light2",

    title: {
      text: "Page Views - Clicks in the Last Month",
    },
    axisX: {
      title: "Page",
      labelWrap: true,
      labelAngle: 0,
      labelFontSize: 10,
    },
    axisY: {
      title: "Clicks",
    },
    data: [
      {
        type: "column",
        dataPoints: viewsPerPage.slice(0, 15),
      },
    ],
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
}

function Chart({ data }: { data: TGetWebUsageSummaryRes }) {
  if (data.status !== "success") {
    return <DashboardLoadError />;
  }

  const viewsPerPage = data.viewsPerPage
    .map((item) => {
      let displayPath;
      if (item.path === "/") {
        displayPath = "Home";
      } else if (item.path === "/FocusHome") {
        displayPath = "Focus Home";
      } else if (item.path.includes("user/concept")) {
        displayPath = `Concept: ${Concepts[item.path.split("/")[4]]?.name ?? "unknown concept"}${
          item.path.split("/")[5] ? " - " + Pathways[item.path.split("/")[5]]?.name : ""
        }`;
      } else if (item.path.includes("user/pathway")) {
        displayPath = `Pathway: ${Pathways[item.path.split("/")[3]]?.name ?? "unknown pathway"}`;
      } else if (item.path.includes("focus/")) {
        displayPath = `Focus: ${Focuses[item.path.split("/")[2]]?.name ?? "unknown focus"}`;
      } else {
        displayPath = item.path
          .split("/")
          .filter((word: string) => word !== "")
          .map((w: string) => w[0].toUpperCase() + w.substring(1).toLowerCase())
          .join(" ");
      }
      return { label: displayPath, y: item.count };
    })
    .sort((a, b) => b.y - a.y);

  const webTraffic = data.webTraffic.map((item) => {
    const date = new Date(item.date);
    date.setHours(0, 0, 0, 0);
    return { x: date, y: item.count, indexLabel: item.count.toString() };
  });

  let tempDate = new Date();
  tempDate.setHours(0, 0, 0, 0);
  Array(29)
    .fill(0)
    .map(() => {
      tempDate = new Date(tempDate);
      tempDate.setDate(tempDate.getDate() - 1);
      return tempDate;
    })
    .forEach((date) => {
      if (!webTraffic.find((item) => item.x.toLocaleDateString() === date.toLocaleDateString())) {
        webTraffic.push({ x: date, y: 0, indexLabel: "0" });
      }
    });

  webTraffic.sort((a, b) => {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  });

  return (
    <>
      <div className="display-4 text-center my-3">Website Analytics Summary</div>
      <WebTrafficChart webTraffic={webTraffic} />
      <ViewsPerPageChart viewsPerPage={viewsPerPage} />
    </>
  );
}

export default function Dashboard() {
  const data = useLoaderData() as {
    webUsageSummaryPromise: Promise<TGetWebUsageSummaryRes | TErrorRes>;
  };

  return (
    <div className="w-100">
      <React.Suspense fallback={<Loading> Loading Dashboard Data </Loading>}>
        <Await resolve={data.webUsageSummaryPromise} errorElement={<DashboardLoadError />}>
          {(data) => <Chart data={data} />}
        </Await>
      </React.Suspense>
    </div>
  );
}
