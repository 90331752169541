import React from "react";
import Videos from "lib/data/Videos";
import PageTitleBanner from "components/templates/PageTitleBanner";
import { SetDocumentTitle } from "utils/SetDocumentTitle";

const VideosBG = require("lib/img/videosBG.png");

export default function VideosPage() {
  SetDocumentTitle("Videos");
  return (
    <div className="w-100">
      <PageTitleBanner
        id="pathways-banner"
        title="How-To Videos"
        img={VideosBG}
        imgPosition="right"
        description="The How-To Videos are a collection of short videos that explain strategic concepts and frameworks in a simple and easy-to-understand manner. Click on the link to explore the How-To Videos and learn more."
      />
      <div className="container">
        <div className="grid-3 my-5">
          {Videos.map(function (video, i) {
            return (
              <a
                key={video.name}
                href={video.url}
                target="_blank"
                rel="noreferrer"
                className="no-underline card mx-3 my-4 p-0 rounded-2 bg-body border-0 shadow-lg-hover"
                style={{ maxWidth: "500px" }}
              >
                <img className="card-img-top video-img" src={video.thumbnail} alt={video.name} />
                <div className="card-body">
                  <h3 className="text-center">{video.name}</h3>
                  <div>{video.summary}</div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
