import { useCallback, useContext, useEffect, useRef, useState } from "react";
import React from "react";
import constants from "Constants";
import Stars from "components/bootstrap_icons/Stars";
import BackToTop from "components/navigation/BackToTop";
import SendArrow from "components/bootstrap_icons/SendArrow";
import useChatBot from "hooks/useChatBot";
import ReactMarkdown from "react-markdown";
import { TChatCompletionMessageParam } from "@sharedTypes/req.type";
import UserContext from "context/UserContext";
import { Transition } from "react-transition-group";
import useWindowSize from "hooks/useWindowSize";
import { ThumbsUpEmpty, ThumbsUpFilled } from "components/bootstrap_icons/ThumbsUp";
import { ThumbsDownEmpty, ThumbsDownFilled } from "components/bootstrap_icons/ThumbsDown";
import ChatBotLikes from "api/tools/chatBot/ChatBotLikes";
import SearchIcon from "components/bootstrap_icons/SearchIcon";
import { Link } from "react-router-dom";

function ChatLoadIcon() {
  return (
    <div className="d-flex">
      <div
        className="rounded-top-4 rounded-end-4 text-secondary"
        style={{ backgroundColor: "#ddd" }}
      >
        <div className="spinner-grow spinner-grow-sm m-2 ms-3"></div>
        <div
          className="spinner-grow spinner-grow-sm my-2"
          style={{ animationDelay: "0.15s" }}
        ></div>
        <div
          className="spinner-grow spinner-grow-sm m-2 me-3"
          style={{ animationDelay: "0.3s" }}
        ></div>
      </div>
    </div>
  );
}

type TChatWindowBtnsProps = {
  setWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function ChatWindowBtns({ setWindowOpen }: TChatWindowBtnsProps) {
  const [backToTop, setBackToTop] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > window.innerHeight - 150) {
      setBackToTop(true);
    } else {
      setBackToTop(false);
    }
  });

  const duration = 300;
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };
  const transitionStyles: { [key: string]: any } = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const nodeRef = useRef(null);

  return (
    <>
      <Transition nodeRef={nodeRef} in={backToTop} timeout={duration} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            className="mb-2"
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            ref={nodeRef}
          >
            <BackToTop />
          </div>
        )}
      </Transition>

      <Link
        to="/user/search"
        className="btn btn-light rounded-circle p-2 d-flex justify-content-center align-items-center shadow-dark mb-2"
        style={{ width: "40px", height: "40px" }}
      >
        <div className="w-100" title="Advanced Search">
          <SearchIcon />
        </div>
      </Link>

      <div
        className=" btn btn-light rounded-circle p-2 d-flex justify-content-center align-items-center shadow-dark"
        onClick={() => setWindowOpen((windowOpen) => !windowOpen)}
        style={{ width: "40px", height: "40px" }}
      >
        <div className="w-100">
          <Stars />
        </div>
      </div>
    </>
  );
}

function AssistantMessage({ message }: { message: TChatCompletionMessageParam }) {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  async function LikeHandler(isLiked: boolean) {
    setLiked(isLiked);
    setDisliked(false);
    const data = await ChatBotLikes(message.chatMessageId, isLiked, false);
    if (data.status !== "success") {
      console.error(data);
    }
  }

  async function DislikeHandler(isDisliked: boolean) {
    setLiked(false);
    setDisliked(isDisliked);
    const data = await ChatBotLikes(message.chatMessageId, false, isDisliked);
    if (data.status !== "success") {
      console.error(data);
    }
  }

  return (
    <div className="text-darker-tan">
      <div className="fw-bold fs-6 noto-serif d-flex mb-2 d-flex align-items-baseline ">
        <div>AI Assistant</div>
        <div className="mx-2">
          <Stars />
        </div>
        <div className="fs-8">Powered by ChatGPT</div>
      </div>
      <ReactMarkdown>{message.content}</ReactMarkdown>
      {message.chatMessageId && (
        <>
          <button className="btn" onClick={() => LikeHandler(!liked)}>
            {liked ? (
              <div className="text-dark-tan">
                <ThumbsUpFilled />
              </div>
            ) : (
              <ThumbsUpEmpty />
            )}
          </button>
          <button className="btn" onClick={() => DislikeHandler(!disliked)}>
            {disliked ? (
              <div className="text-dark-tan">
                <ThumbsDownFilled />
              </div>
            ) : (
              <ThumbsDownEmpty />
            )}
          </button>
        </>
      )}
    </div>
  );
}

function UserMessage({ message }: { message: TChatCompletionMessageParam }) {
  const { user } = useContext(UserContext);
  return (
    <>
      <div className="text-end">
        <div className="mb-2 fw-bold fs-6 noto-serif ">
          {user.firstName} {user.lastName}
        </div>
        <ReactMarkdown>{message.content}</ReactMarkdown>
      </div>
    </>
  );
}

type TChatWindowProps = {
  setWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function ChatWindow({ setWindowOpen }: TChatWindowProps) {
  const { messages, ClearMessages, loading, sendChat } = useChatBot();

  const formRef = useRef<HTMLFormElement>(null);

  function SendChatHandler(e: any, chatInput?: string) {
    e.preventDefault();
    const chat = chatInput ?? e.target["chat"].value;
    if (formRef.current) {
      formRef.current.reset();
    }
    if (!chat) {
      return;
    }
    sendChat(chat);
  }

  const bottomRef = useRef<HTMLDivElement>(null);

  function ScrollToBottom(bottomRef: React.RefObject<HTMLDivElement>) {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    ScrollToBottom(bottomRef);
  }, [messages, bottomRef]);

  ScrollToBottom(bottomRef);

  const suggestions = [
    "How can I align my company with its purpose?",
    "How can I prevent imitation by leveraging location advantages?",
    "Explain Blue Ocean Strategy.",
    "How can I identify customer unmet needs?",
  ];

  return (
    <div className="d-flex h-100 flex-column p-3 pt-0 justify-content-end overflow-y-auto ">
      <div
        className="position-absolute end-0 top-0 p-2 m-2 bg-white rounded-circle shadow-sm"
        onClick={() => setWindowOpen(false)}
        style={{ cursor: "pointer" }}
      >
        <button type="button" className="btn-close" aria-label="Close" />
      </div>
      <div className="overflow-y-auto mb-3 " style={{ scrollbarWidth: "none" }}>
        {messages.map((message, index) => {
          if (message.role === "system") {
            return null;
          }
          return (
            <div key={index} className="open-sans">
              {message.role === "assistant" ? (
                <AssistantMessage message={message} />
              ) : (
                <UserMessage message={message} />
              )}
              {(index !== messages.length - 1 || message.role === "user") && <hr />}
            </div>
          );
        })}
        {loading && <ChatLoadIcon />}
        <div ref={bottomRef} />
      </div>
      {messages.length === 2 &&
        suggestions.map((suggestion, index) => (
          <button
            key={index}
            onClick={(e) => SendChatHandler(e, suggestion)}
            type="button"
            className="btn btn-primary mb-2 fs-7"
            name="chat"
            value=""
          >
            {suggestion}
          </button>
        ))}
      <form onSubmit={SendChatHandler} ref={formRef}>
        <div className="input-group mb-3">
          <input
            type="text"
            name="chat"
            className="form-control"
            placeholder="Tell me about..."
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            autoComplete="off"
          />
          <div className="input-group-append">
            <button className="btn border rounded-0 rounded-end " type="submit">
              <SendArrow />
            </button>
          </div>
        </div>
      </form>
      <button className="btn btn-outline-secondary" onClick={ClearMessages}>
        Clear chat
      </button>
      <div className="text-center fs-7 mt-2">
        *AI Assistant is under development. Provided links may be innacurate. Try the search bar.
      </div>
    </div>
  );
}

export default function ChatWindowContainer() {
  const [windowOpen, setWindowOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(350);
  const screenSmall = useWindowSize().width < 768;

  const duration = 500;

  const transitionStyles: { [key: string]: any } = {
    entering: { transition: `width ${duration}ms`, width: windowWidth },
    entered: { width: windowWidth },
    exiting: { transition: `width ${duration}ms`, width: 0 },
    exited: { width: 0 },
  };

  const nodeRef = useRef(null);

  const resizerRef = useRef<HTMLDivElement>(null);

  const mouseDownHandler = useCallback((e: MouseEvent, windowWidth: number) => {
    e.preventDefault();

    const minWindowWidth = 300;
    const startX = e.clientX;
    const startWidth = windowWidth;

    const mouseMoveHandler = (e: MouseEvent) => {
      e.preventDefault();
      let newWidth = startWidth + startX - e.clientX;
      newWidth = Math.max(minWindowWidth, newWidth);
      setWindowWidth(newWidth);
    };

    const mouseUpHandler = (e: MouseEvent) => {
      e.preventDefault();
      window.removeEventListener("mousemove", mouseMoveHandler);
      window.removeEventListener("mouseup", mouseUpHandler);
    };

    window.addEventListener("mousemove", mouseMoveHandler);
    window.addEventListener("mouseup", mouseUpHandler);
  }, []);

  useEffect(() => {
    const resizer = resizerRef.current;
    if (!resizer) {
      return;
    }

    resizer.addEventListener("mousedown", (e) => mouseDownHandler(e, windowWidth));
  }, [resizerRef, mouseDownHandler, windowWidth]);

  return (
    <div>
      <Transition nodeRef={nodeRef} in={windowOpen} timeout={duration}>
        {(state) => (
          <>
            <div
              className={screenSmall ? "d-none" : ""}
              onClick={() => setWindowOpen((windowOpen) => !windowOpen)}
              style={{ ...transitionStyles[state] }}
              ref={nodeRef}
            ></div>
            <div
              className={`position-fixed z-1 bg-white end-0 ${
                windowOpen && "border-start border-2"
              }`}
              style={{ height: window.innerHeight - constants.navHeight }}
            >
              <div
                className="h-100"
                style={{
                  ...transitionStyles[state],
                  overflow: "hidden",
                }}
              >
                <div className="h-100  position-relative" style={{ width: windowWidth }}>
                  <ChatWindow setWindowOpen={setWindowOpen} />
                </div>
              </div>

              <div className="position-absolute m-3" style={{ bottom: 0, right: "100%" }}>
                <ChatWindowBtns setWindowOpen={setWindowOpen} />
              </div>
              <div
                className="position-absolute top-0 h-100 d-flex align-items-center justify-content-center"
                style={{ width: 0, overflow: "visible" }}
              >
                <div
                  className="h-100 sidebar-border-hover"
                  ref={resizerRef}
                  style={{ minWidth: 8, cursor: "ew-resize" }}
                ></div>
              </div>
            </div>
          </>
        )}
      </Transition>
    </div>
  );
}
