import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";

export default function DefineVisionPathway() {
  return (
    <div>
      <p>
        Values supplement the mission and vision statements additionally by stating the way the
        company will accomplish its mission and bring about its vision. Like the mission and vision,
        values must also be strategic and motivating, but in this case strategic is more important
        than motivation. Therefore, virtues are not helpful because it infers that we could chose to
        lie or cheat vs be honest and play fair.
      </p>

      <p>
        Vales should direct what the company will do and what it will not do. They define what the
        priorities of the company are and what it believes will fulfill their mission.
      </p>

      <div>
        Values should accomplish at least four things:
        <ol>
          <li>Reflect choices that are consistent with the strategy</li>
          <li>Be aligned with employers' and (ideally) customers' values</li>
          <li>Not statements to be ethical; these values are not choices but are assumed</li>
          <li>Be unique to the company</li>
        </ol>
      </div>

      <ExampleContainer>
        <Example header="Patagonia">
          <ul>
            <li>
              Quality: Build the best product, provide the best service and constantly improve
              everything we do
            </li>
            <li>
              Integrity: Examine our practices openly and honestly, learn from our mistakes and meet
              our commitments.
            </li>
            <li>Environmentalism: Protect our home planet</li>
            <li>Justice: Be just, equitable and antiracist as a company and in our community</li>
            <li>Not bound by convention: Do it our way</li>
          </ul>
          <p>
            Patagonia's values could be critiqued but the important part is that each of their
            values is clearly recognizable in the organization. Its clear that these are Patagonia
            and Patagonia are these
          </p>
        </Example>

        <Example header="Enron">
          <ul>
            <li>
              Communication: Enron has an obligation to communicate, and takes time to listen and
              talk with each other.
            </li>
            <li>Respect: Enron treats others as it would like to be treated.</li>
            <li>
              Integrity: Enron works with customers and prospects openly, honestly, and sincerely.
            </li>
            <li>
              Excellence: Enron is satisfied with nothing less than the best in everything it does.
            </li>
          </ul>
          <p>
            Enron was one of the biggest fraud cases in America, yet their values are only virtues.
            It doesn't state any strategy or how they want to operate. Also, a virtue of
            communication is hardly necessary, unless there is a preference to not communicate.
          </p>
        </Example>
      </ExampleContainer>
    </div>
  );
}
