import React from "react";
import { Link } from "react-router-dom";
import Pathways from "lib/data/Pathways";
import Focuses from "lib/data/Focuses";

const BYULogo = require("lib/img/BYU_Logos/Combined Mark/PNG/BYU MonogramWordmark_white@2x.png");
const YoutubeIcon = require("lib/img/icons/youtubeIcon.png");

function Attributions() {
  const attributions = [
    {
      name: "Collapse icons created by Lizel Arina - Flaticon",
      link: "https://www.flaticon.com/free-icons/collapse",
      title: "collapse icons",
    },
    {
      name: "Brands and logotypes icons created by Freepik - Flaticon",
      link: "https://www.flaticon.com/free-icons/brands-and-logotypes",
      title: "brands and logotypes icons",
    },
    {
      name: "Back icons created by Arkinasi - Flaticon",
      link: "https://www.flaticon.com/free-icons/back",
      title: "back icons",
    },
  ];

  return (
    <>
      <strong>Attributions</strong>
      <hr />
      <ul className="list-unstyled">
        {attributions.map((attribution, i) => {
          return (
            <li key={i} className="lh-1 mb-2">
              <a
                href={attribution.link}
                title={attribution.title}
                className="text-white no-underline underline-on-hover "
              >
                {attribution.name}
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default function Footer() {
  const pageLinks = [
    { name: "Home", url: "/" },
    { name: "The Codex", url: "/codex" },
    { name: "FOCUS", url: "/focushome" },
    { name: "Meet the Team", url: "/team" },
    { name: "Tools", url: "/tools" },
  ];
  return (
    <>
      <footer className="bg-darker-tan text-white z-1">
        <div style={{ height: 100 }}></div>
        <div className="container" style={{ maxWidth: 1000 }}>
          <div className="row">
            <div className="col-md-3">
              <strong>Pages</strong>
              <hr />
              <ul className="list-unstyled">
                {pageLinks.map((link, i) => {
                  return (
                    <li key={i}>
                      <Link to={link.url} className="text-white no-underline underline-on-hover">
                        {link.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <br />
              <strong>Other</strong>
              <hr />
              <ul className="list-unstyled">
                <li className="text-white no-underline text-start text-md-center text-lg-start">
                  <Link
                    to={"https://www.youtube.com/@BYUStrategyLab"}
                    target="_blank"
                    className="text-light no-underline underline-on-hover"
                  >
                    <img src={YoutubeIcon} alt="" /> @BYUStrategyLab
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <Link to="/FocusHome" className="text-white fw-bold no-underline underline-on-hover">
                Strategy FOCUS
              </Link>
              <hr />
              <div className="two-columns">
                {Object.values(Focuses).map((focus, i) => {
                  return (
                    <div key={focus.id} className="mb-4 ">
                      <Link to={focus.url} className="text-white no-underline underline-on-hover">
                        <strong>{focus.name}</strong>
                      </Link>
                      <hr />
                      {Object.values(Pathways).map((pathway, i) => {
                        if (pathway.focus !== focus.id) return null;
                        return (
                          <div key={pathway.id} className="ps-2 lh-1 mb-2">
                            <Link
                              to={pathway.url}
                              className="text-white no-underline underline-on-hover"
                            >
                              {pathway.name}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-3">
              <Attributions />
            </div>
          </div>
        </div>
        <div style={{ height: 35 }}></div>

        <div className="d-block text-center">
          <img src={BYULogo} alt="Byu Logo" width={300} />
          <br />
          <small>
            &copy; {new Date().getFullYear()} - Brigham Young University - All Rights Reserved
          </small>
        </div>
        <div style={{ height: 100 }}></div>
      </footer>
    </>
  );
}
