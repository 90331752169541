import Example from "components/concepts/templates/Example";
import ExampleContainer from "components/concepts/templates/ExampleContainer";
import Image from "components/concepts/templates/Image";
import React from "react";

export default function DefineMissionPathway() {
  return (
    <div>
      <p>
        One of the most important characteristic that investors look for in a company is a
        leadership team that works together and has direction. This is largely due to how united the
        team is. The mission statement defines what is uniting the leadership team and the company
        together. Without it, each employee of the company can have their own view of what the
        company is and where it should end up, resulting in confusion and divergence as the company
        grows.
      </p>
      <p>
        The mission statement should also describe why the company is in business, and what the
        company aspires to attain. It must transcend the desire for monetary gains and, instead,
        reflect how company financial success will make the community, the country, or the world, a
        better place beyond the products that it sells. The further the mission gets from the needs
        of the business, the more effective it will be to inspire individuals inside and outside the
        company. However, it must be actionable within the company itself.
      </p>
      <div>
        A mission statement should have the following:
        <ul>
          <li>Transcend product and market life cycles</li>
          <li>Be motivating and inspiring</li>
          <li>Reflect a choice</li>
          <li>Be short, clear, and easy to remember</li>
          <li>It should be unique in reflecting the company that it belongs to</li>
          <li>It must be coherent with the Vision and Values of the company"</li>
        </ul>
      </div>
      <ExampleContainer>
        <Example header="Forbes">
          <p>
            "To deliver information on the people, ideas and technologies changing the world to our
            community of affluent business decision makers"
          </p>
          <p>
            This mission statement is strategic, dictating where and how they will act but doesn't
            instill inspiration or motivation.
          </p>
        </Example>

        <Example header="American Red Cross">
          <p>
            "To prevent and alleviate human suffering in the face of emergencies by mobilizing the
            power of volunteers and the generosity of donors"
          </p>

          <p>
            This example is very motivating as it talks to things that matter to everyone: human
            suffering and emergencies. It also has strategy, citing that it mobilizes volunteers and
            donors help to achieve this mission."
          </p>
        </Example>
        <Example header="Nike">
          <p>
            "Bring inspiration and innovation to every athlete in the world. If you have a body, you
            are an athlete."
          </p>
          <p>
            Nike has an interesting take on mission statements. In defining what an 'athlete' is to
            itself, it also defines it for all who read this statement. It is clearly motivational
            as it hits the human desire to be fit, and defines its simple strategy how it will do
            so.
          </p>
        </Example>
        <Example header="Albertsons">
          <p>
            "To create a shopping experience that pleases our customers; a workplace that creates
            opportunities and a great working environment for our associates; and a business that
            achieves financial success"
          </p>

          <p>
            Unfortunately, this isn't inspirational or strategic. It states what they'd like to
            achieve but doesn't have any indication how the company will achieve these goals. In
            addition, the goals are as inspiring as new year's resolutions no one seems to reach."
          </p>
        </Example>

        <Example header="Fred Meyer">
          <p>
            "While our stores change with the times, one thing stays the same - our commitment to
            Customers"
          </p>

          <p>
            This is unique, in that it has almost no strategy how it will achieve its mission but
            the statement is so strong it makes the reader clench their fist with determination,
            like its a call to battle or to weather a storm."
          </p>
        </Example>
        <Example header="Starbucks">
          <p>
            "With every cup, with every conversation, with every community - we nurture the
            limitless possibilities of human connection"
          </p>

          <p>
            Inspriational and strategic, Starbucks indicates why its in business and how it will do
            it. Limited possibilities brings emotions while cups clearly define its a beverage
            company that facilitates meeting together."
          </p>
        </Example>
        <Example header="USPS">
          <p> "To provide the nation with reliable, affordable, universal mail service. "</p>
          <p>
            Not strategic (how it will do it) or inspirational. Interestingly enough, the law that
            created the USPS states the following about the purpose of the USPS:
          </p>

          <p>
            "...to bind the Nation together through the personal, educational, literary, and
            business correspondence of the people."
          </p>

          <p>This is inspriational and it clearly defines strategy how it will do it.</p>
        </Example>
      </ExampleContainer>

      <div className="px-3 py-2 bg-light rounded border">
        <p>
          The framework below helps evaluate the mission, vision, and value statements, indivudally
          and collectively, to decide if they are motivational, have strategy, and if those are
          coherent with each other.
        </p>
        <p>
          Patagonia's mission, "We're in business to save our home planet," is very inspirational
          but has no strategy to it at all. They instead stretch their Values to include how they
          will make this happen. In this way, the culmination of their mission, vision, and values
          show motivation, strategy, and cohesion.
        </p>
        <Image src={require("lib/img/pathways/DefineMission.png")} alt="" />
      </div>
    </div>
  );
}
