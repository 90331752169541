import React, { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Searchbar from "components/navigation/SearchBar";
import { useSearchParams } from "react-router-dom";
import UserContext from "context/UserContext";
import { TUser } from "types/UserType";
import constants from "Constants";
import User from "components/bootstrap_icons/User";
import { Dropdown, DropdownButton } from "react-bootstrap";
import useElementSize from "hooks/useElementSize";

function UserNabTabTitle({ user }: { user: TUser }) {
  const [usernameElement, { height: usernameElementHeight }] = useElementSize();

  return (
    <>
      <User width={usernameElementHeight} height={usernameElementHeight} className="me-2" />
      <div className="fs-6" ref={usernameElement}>
        {user.firstName} {user.lastName}
      </div>
    </>
  );
}

function UserNavTab({ user }: { user: TUser }) {
  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    window.location.reload();
  };

  return (
    <div className="position-relative z-3" id="user-tab">
      <DropdownButton
        variant={`btn-link border-0 text-white d-flex align-items-center`}
        title={<UserNabTabTitle user={user} />}
      >
        <Dropdown.Item>
          <Link to={"/user/account"} className="dropdown-item">
            Manage Account
          </Link>
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item>
          <div className="dropdown-item" id="logout-btn" onClick={logoutHandler}>
            Logout
          </div>
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}

export default function Nav() {
  const [collapsed, setCollapsed] = useState(true);
  const { user } = useContext(UserContext);

  const searchParams = useSearchParams()[0];
  let return_url = searchParams.get("return_url") ?? window.location.pathname;
  if (["/login", "/register/", "/forgotpassword"].includes(return_url)) {
    return_url = "/";
  }
  const collapsedHandler = () => {
    setCollapsed(!collapsed);
  };

  window.addEventListener("click", (e: any) => {
    const navIdList = [
      "nav",
      "navbar-btn",
      "navbar-nav",
      "navbar-btn-icon",
      "search-link",
      "searchbar",
      "search-input",
      "searchbar-dropdown",
      "search-link",
      "user-tab",
      "input-group-dropdown-1",
      "dropdown-link",
    ];
    if (!navIdList.includes(e.target.id)) {
      setCollapsed(true);
    }
  });

  const navBtns = [
    {
      name: "Home",
      url: "/",
      admin: false,
    },
    {
      name: "The Codex",
      url: "/codex",
      admin: false,
    },
    {
      name: "FOCUS",
      url: "/FocusHome",
      admin: false,
    },

    {
      name: "The Team",
      url: "/team",
      admin: false,
    },
    {
      name: "Tools",
      url: "/tools",
      admin: false,
    },
    {
      name: "Dashboard",
      url: "/admin/dashboard",
      admin: true,
    },
  ];

  return (
    <>
      <nav
        className="navbar navbar-expand-xl p-2 ps-0 text-light fixed-top bg-darker-tan"
        id="nav"
        style={{ minHeight: constants.navHeight, position: "sticky" }}
      >
        <Link className="navbar-brand mx-2 mx-xl-4 fs-5" to="/">
          Strategy Unlocked
        </Link>
        <button
          className="navbar-toggler navbar-dark navbar"
          id="navbar-btn"
          type="button"
          onClick={collapsedHandler}
          aria-controls="navbar-btn"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" id="navbar-btn-icon"></span>
        </button>
        <div className={"navbar-collapse " + (collapsed ? " collapse" : "")} id="navbar-nav">
          <ul className="navbar-nav w-100 d-flex">
            {navBtns.map((navBtn, i) => {
              let isActive =
                window.location.pathname === navBtn.url ||
                (navBtn.url === "/FocusHome" && window.location.pathname.includes("/focus")) ||
                (navBtn.url === "/tools" && window.location.pathname.includes("/tool"));
              if (navBtn.admin && !user.isAdmin) {
                return null;
              }
              return (
                <li className="nav-item px-2 px-xl-0 no-word-wrap " key={navBtn.name}>
                  <Link
                    onClick={collapsedHandler}
                    to={navBtn.url}
                    className={`nav-link ${!isActive ? "text-tan-3" : ""}`}
                  >
                    {navBtn.name}
                  </Link>
                </li>
              );
            })}
            <li className="nav-item px-2 px-xl-0 no-word-wrap text-decoration-underline w-100">
              <Searchbar collapsed={collapsed} setCollapsed={setCollapsed} />
            </li>
            {localStorage.getItem("token") ? (
              <li
                id="user-tab"
                className="nav-item px-2 px-xl-0 no-word-wrap d-flex align-items-center  "
              >
                <UserNavTab user={user} />
              </li>
            ) : (
              <li className="nav-item px-2 px-xl-0 no-word-wrap  ">
                <Link
                  onClick={() => {
                    collapsedHandler();
                  }}
                  to={"login/?return_url=" + return_url}
                  className="nav-link"
                >
                  Log In
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}
