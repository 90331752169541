import { TCategories } from "types/JsonType";

export const Categories = {
  CorporateStrategy: { name: "Corporate Strategy" },
  Growth: { name: "Growth" },
  IndustryStructure: { name: "Industry Structure" },
  Innovation: { name: "Innovation" },
  InternationalStrategy: { name: "International Strategy" },
  Cost: { name: "Cost" },
  ProblemSolving: { name: "Problem Solving" },
  Investments: { name: "Investments" },
  Marketing: { name: "Marketing" },
  Leadership: { name: "Leadership" },
  Marketplaces: { name: "Marketplaces" },
  Acquisition: { name: "Acquisition" },
  Economics: { name: "Economics" },
  Entrepreneurship: { name: "Entrepreneurship" },
  MISC: { name: "MISC" },
} as const satisfies TCategories;

export default Categories as TCategories;
