import { Breadcrumb } from "./Breadcrumb";
import NavArrows from "components/navigation/NavArrows";
import { TConcept, TPathway, TFocus } from "types/JsonType";
import { Concepts } from "lib/data/Concepts";
import { Pathways } from "lib/data/Pathways";
import { Focuses } from "lib/data/Focuses";
import useWindowSize from "hooks/useWindowSize";
import constants from "Constants";

type TBreadcrumbsAndArrowsProps = {
  focus: TFocus;
  pathway?: TPathway;
  concept?: TConcept;
};

type TPath = {
  id: string;
  url: string;
};

export default function BreadcrumbsAndArrows({
  focus,
  pathway,
  concept,
}: TBreadcrumbsAndArrowsProps) {
  if (useWindowSize().width < 768) {
    return null;
  }
  let paths: TPath[];
  let pageId: string;

  if (concept && pathway) {
    paths = pathway?.concepts
      .map((conceptId) => Concepts[conceptId])
      .reduce((acc, concept) => {
        acc.push({ id: concept.id, url: concept.url });
        return acc;
      }, [] as TPath[]);
    pageId = concept.id;
  } else if (pathway) {
    paths = Object.values(Pathways)
      .filter((pathway) => pathway.focus === focus.id)
      .reduce((acc, pathway) => {
        acc.push({ id: pathway.id, url: pathway.url });
        return acc;
      }, [] as TPath[]);

    pageId = pathway.id;
  } else {
    paths = Object.values(Focuses).reduce((acc, focus) => {
      acc.push({ id: focus.id, url: focus.url });
      return acc;
    }, [] as TPath[]);
    pageId = focus.id;
  }

  const index = paths.findIndex((path) => path.id === pageId);
  let leftPath = paths[index - 1]?.url;
  let rightPath = paths[index + 1]?.url;

  if (concept && leftPath) {
    leftPath += `/${pathway?.id}`;
  }
  if (concept && rightPath) {
    rightPath += `/${pathway?.id}`;
  }

  return (
    <div
      className="position-sticky d-flex justify-content-between z-1 "
      style={{ top: constants.navHeight }}
    >
      <Breadcrumb focus={focus} pathway={pathway} concept={concept} />
      <NavArrows rightPath={rightPath} leftPath={leftPath} />
    </div>
  );
}
