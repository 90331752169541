export const animation = {
  v: "5.9.0",
  fr: 24,
  ip: 0,
  op: 239,
  w: 5760,
  h: 5760,
  nm: "New Vector Zen",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Mini Circle Control",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [2880, 2880, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2880, 2880, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Delay",
          np: 3,
          mn: "ADBE Slider Control",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Slider",
              mn: "ADBE Slider Control-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  { i: { x: [0.483], y: [0.815] }, o: { x: [0.004], y: [0.387] }, t: 3, s: [0] },
                  { t: 57, s: [3] },
                ],
                ix: 1,
              },
            },
          ],
        },
        {
          ty: 5,
          nm: "Animation",
          np: 3,
          mn: "ADBE Slider Control",
          ix: 2,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Slider",
              mn: "ADBE Slider Control-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  { i: { x: [0.257], y: [1] }, o: { x: [0.291], y: [0.001] }, t: 0, s: [0] },
                  { t: 24.0244140625, s: [100] },
                ],
                ix: 1,
              },
            },
          ],
        },
      ],
      ip: 0,
      op: 719.91991991992,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 1,
      nm: "White Solid 2",
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [2880, 2880, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2880, 2880, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Fractal Noise",
          np: 33,
          mn: "ADBE Fractal Noise",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: "Fractal Type",
              mn: "ADBE Fractal Noise-0001",
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 7,
              nm: "Noise Type",
              mn: "ADBE Fractal Noise-0002",
              ix: 2,
              v: { a: 0, k: 3, ix: 2 },
            },
            { ty: 7, nm: "Invert", mn: "ADBE Fractal Noise-0003", ix: 3, v: { a: 0, k: 0, ix: 3 } },
            {
              ty: 0,
              nm: "Contrast",
              mn: "ADBE Fractal Noise-0004",
              ix: 4,
              v: { a: 0, k: 119, ix: 4 },
            },
            {
              ty: 0,
              nm: "Brightness",
              mn: "ADBE Fractal Noise-0005",
              ix: 5,
              v: { a: 0, k: 48, ix: 5 },
            },
            {
              ty: 7,
              nm: "Overflow",
              mn: "ADBE Fractal Noise-0006",
              ix: 6,
              v: { a: 0, k: 4, ix: 6 },
            },
            { ty: 6, nm: "Transform", mn: "ADBE Fractal Noise-0007", ix: 7, v: 0 },
            {
              ty: 0,
              nm: "Rotation",
              mn: "ADBE Fractal Noise-0008",
              ix: 8,
              v: { a: 0, k: 0, ix: 8 },
            },
            {
              ty: 7,
              nm: "Uniform Scaling",
              mn: "ADBE Fractal Noise-0009",
              ix: 9,
              v: { a: 0, k: 1, ix: 9 },
            },
            {
              ty: 0,
              nm: "Scale",
              mn: "ADBE Fractal Noise-0010",
              ix: 10,
              v: { a: 0, k: 100, ix: 10 },
            },
            {
              ty: 0,
              nm: "Scale Width",
              mn: "ADBE Fractal Noise-0011",
              ix: 11,
              v: { a: 0, k: 100, ix: 11 },
            },
            {
              ty: 0,
              nm: "Scale Height",
              mn: "ADBE Fractal Noise-0012",
              ix: 12,
              v: { a: 0, k: 100, ix: 12 },
            },
            {
              ty: 3,
              nm: "Offset Turbulence",
              mn: "ADBE Fractal Noise-0013",
              ix: 13,
              v: { a: 0, k: [2880, 2880], ix: 13 },
            },
            {
              ty: 7,
              nm: "Perspective Offset",
              mn: "ADBE Fractal Noise-0031",
              ix: 14,
              v: { a: 0, k: 0, ix: 14 },
            },
            { ty: 6, nm: "", mn: "ADBE Fractal Noise-0014", ix: 15, v: 0 },
            {
              ty: 0,
              nm: "Complexity",
              mn: "ADBE Fractal Noise-0015",
              ix: 16,
              v: { a: 0, k: 6, ix: 16 },
            },
            { ty: 6, nm: "Sub Settings", mn: "ADBE Fractal Noise-0016", ix: 17, v: 0 },
            {
              ty: 0,
              nm: "Sub Influence (%)",
              mn: "ADBE Fractal Noise-0017",
              ix: 18,
              v: { a: 0, k: 70, ix: 18 },
            },
            {
              ty: 0,
              nm: "Sub Scaling",
              mn: "ADBE Fractal Noise-0018",
              ix: 19,
              v: { a: 0, k: 56, ix: 19 },
            },
            {
              ty: 0,
              nm: "Sub Rotation",
              mn: "ADBE Fractal Noise-0019",
              ix: 20,
              v: { a: 0, k: 0, ix: 20 },
            },
            {
              ty: 3,
              nm: "Sub Offset",
              mn: "ADBE Fractal Noise-0020",
              ix: 21,
              v: { a: 0, k: [0, 0], ix: 21 },
            },
            {
              ty: 7,
              nm: "Center Subscale",
              mn: "ADBE Fractal Noise-0021",
              ix: 22,
              v: { a: 0, k: 0, ix: 22 },
            },
            { ty: 6, nm: "", mn: "ADBE Fractal Noise-0022", ix: 23, v: 0 },
            {
              ty: 0,
              nm: "Evolution",
              mn: "ADBE Fractal Noise-0023",
              ix: 24,
              v: {
                a: 0,
                k: 0,
                ix: 24,
                x: "var $bm_rt;\nposterizeTime(5);\n$bm_rt = random(10000);",
              },
            },
            { ty: 6, nm: "Evolution Options", mn: "ADBE Fractal Noise-0024", ix: 25, v: 0 },
            {
              ty: 7,
              nm: "Cycle Evolution",
              mn: "ADBE Fractal Noise-0025",
              ix: 26,
              v: { a: 0, k: 0, ix: 26 },
            },
            {
              ty: 0,
              nm: "Cycle (in Revolutions)",
              mn: "ADBE Fractal Noise-0026",
              ix: 27,
              v: { a: 0, k: 1, ix: 27 },
            },
            {
              ty: 0,
              nm: "Random Seed",
              mn: "ADBE Fractal Noise-0027",
              ix: 28,
              v: { a: 0, k: 0, ix: 28 },
            },
            { ty: 6, nm: "Random Seed", mn: "ADBE Fractal Noise-0028", ix: 29, v: 0 },
            {
              ty: 0,
              nm: "Opacity",
              mn: "ADBE Fractal Noise-0029",
              ix: 30,
              v: { a: 0, k: 100, ix: 30 },
            },
            {
              ty: 7,
              nm: "Blending Mode",
              mn: "ADBE Fractal Noise-0030",
              ix: 31,
              v: { a: 0, k: 2, ix: 31 },
            },
          ],
        },
      ],
      sw: 5760,
      sh: 5760,
      sc: "#ffffff",
      ip: 0,
      op: 725,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Layer 1 Outlines",
      tt: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [3032, 2683.999, 0], ix: 2, l: 2 },
        a: { a: 0, k: [2226.133, 2442.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Turbulent Displace",
          np: 16,
          mn: "ADBE Turbulent Displace",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: "Displacement",
              mn: "ADBE Turbulent Displace-0001",
              ix: 1,
              v: { a: 0, k: 1, ix: 1 },
            },
            {
              ty: 0,
              nm: "Amount",
              mn: "ADBE Turbulent Displace-0002",
              ix: 2,
              v: { a: 0, k: 35, ix: 2 },
            },
            {
              ty: 0,
              nm: "Size",
              mn: "ADBE Turbulent Displace-0003",
              ix: 3,
              v: { a: 0, k: 4, ix: 3 },
            },
            {
              ty: 3,
              nm: "Offset (Turbulence)",
              mn: "ADBE Turbulent Displace-0004",
              ix: 4,
              v: { a: 0, k: [2880, 2880], ix: 4 },
            },
            {
              ty: 0,
              nm: "Complexity",
              mn: "ADBE Turbulent Displace-0005",
              ix: 5,
              v: { a: 0, k: 1, ix: 5 },
            },
            {
              ty: 0,
              nm: "Evolution",
              mn: "ADBE Turbulent Displace-0006",
              ix: 6,
              v: {
                a: 0,
                k: 0,
                ix: 6,
                x: "var $bm_rt;\nposterizeTime(4);\n$bm_rt = random(10000);",
              },
            },
            { ty: 6, nm: "Evolution Options", mn: "ADBE Turbulent Displace-0007", ix: 7, v: 0 },
            {
              ty: 7,
              nm: "Cycle Evolution",
              mn: "ADBE Turbulent Displace-0008",
              ix: 8,
              v: { a: 0, k: 0, ix: 8 },
            },
            {
              ty: 0,
              nm: "Cycle (in Revolutions)",
              mn: "ADBE Turbulent Displace-0009",
              ix: 9,
              v: { a: 0, k: 1, ix: 9 },
            },
            {
              ty: 0,
              nm: "Random Seed",
              mn: "ADBE Turbulent Displace-0010",
              ix: 10,
              v: { a: 0, k: 0, ix: 10 },
            },
            { ty: 6, nm: "Random Seed", mn: "ADBE Turbulent Displace-0011", ix: 11, v: 0 },
            {
              ty: 7,
              nm: "Pinning",
              mn: "ADBE Turbulent Displace-0012",
              ix: 12,
              v: { a: 0, k: 3, ix: 12 },
            },
            {
              ty: 7,
              nm: "Resize Layer",
              mn: "ADBE Turbulent Displace-0013",
              ix: 13,
              v: { a: 0, k: 0, ix: 13 },
            },
            {
              ty: 7,
              nm: "Antialiasing for Best Quality",
              mn: "ADBE Turbulent Displace-0014",
              ix: 14,
              v: { a: 0, k: 1, ix: 14 },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -1050.245],
                    [1050.245, 0],
                    [0, 1050.245],
                    [-1050.246, 0],
                  ],
                  o: [
                    [0, 1050.245],
                    [-1050.246, 0],
                    [0, -1050.245],
                    [1050.245, 0],
                  ],
                  v: [
                    [1901.637, 0],
                    [0.001, 1901.637],
                    [-1901.637, 0],
                    [0.001, -1901.637],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "tm",
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.26], y: [1] }, o: { x: [0.333], y: [0] }, t: 86, s: [0] },
                  { t: 106, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: -132, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 50, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [2226.132, 2441.217], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Outer Circle",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1591.911, -919.091],
                    [0, -1838.18],
                    [1591.911, -919.091],
                    [1591.911, 919.09],
                    [0, 1838.18],
                    [-1591.911, 919.09],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 50, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tm",
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.335], y: [1] }, o: { x: [0.333], y: [0] }, t: 81, s: [0] },
                  { t: 101, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 137, ix: 3 },
              m: 1,
              ix: 3,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [2226.132, 2442.503], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Outer Hexagon",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-510.125, -294.521],
                    [-510.125, 294.521],
                    [0, 589.042],
                    [510.125, 294.521],
                    [510.125, -294.521],
                    [0, -589.042],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 50, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tm",
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  { i: { x: [0.367], y: [1] }, o: { x: [0.333], y: [0] }, t: 71, s: [0] },
                  { t: 91, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: -25, ix: 3 },
              m: 1,
              ix: 3,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [2226.132, 2442.502], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Inner Hexagon",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(0.125);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 15, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2226.132, 2442.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(0.25);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 82, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [1526.816, 2038.695], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(0.375);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 21, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [1526.816, 2846.251], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(0.5);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: -40, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2226.132, 3250.001], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "4",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(0.625);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: -100, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2925.448, 2846.251], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(0.75);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: -160, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2925.448, 2038.751], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "6",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(0.875);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: -220, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2226.132, 1635.001], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "7",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 445.97],
                        [445.97, 0],
                        [0, -445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, -445.97],
                        [-445.97, 0],
                        [0, 445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, -807.5],
                        [-807.5, 0],
                        [0, 807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 20, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [1526.818, 1231.251], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "8",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 445.97],
                        [445.97, 0],
                        [0, -445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, -445.97],
                        [-445.97, 0],
                        [0, 445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, -807.5],
                        [-807.5, 0],
                        [0, 807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1.125);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 78, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [827.5, 2442.501], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "9",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 445.97],
                        [445.97, 0],
                        [0, -445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, -445.97],
                        [-445.97, 0],
                        [0, 445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, -807.5],
                        [-807.5, 0],
                        [0, 807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1.25);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 139, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [1526.818, 3653.751], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "10",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 445.97],
                        [445.97, 0],
                        [0, -445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, -445.97],
                        [-445.97, 0],
                        [0, 445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, -807.5],
                        [-807.5, 0],
                        [0, 807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1.375);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 200, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2925.448, 3653.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "11",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 445.97],
                        [445.97, 0],
                        [0, -445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, -445.97],
                        [-445.97, 0],
                        [0, 445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, -807.5],
                        [-807.5, 0],
                        [0, 807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1.5);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 259, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [3624.764, 2442.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "12",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 445.97],
                        [445.97, 0],
                        [0, -445.97],
                        [-445.969, 0],
                      ],
                      o: [
                        [0, -445.97],
                        [-445.969, 0],
                        [0, 445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, -807.5],
                        [-807.5, 0],
                        [0, 807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1.625);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 321, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2925.448, 1231.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "13",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.969, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.969, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1.75);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 140, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [827.502, 1635.001], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "14",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(1.875);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 81, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [827.5, 3250.002], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "15",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(2);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: 20, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2226.132, 4057.499], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "16",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.969, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.969, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(2.125);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: -40, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [3624.766, 3249.999], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "17",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.969, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.969, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(2.25);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: -101, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [3624.766, 1635], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "18",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 18,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -445.97],
                        [445.97, 0],
                        [0, 445.97],
                        [-445.97, 0],
                      ],
                      o: [
                        [0, 445.97],
                        [-445.97, 0],
                        [0, -445.97],
                        [445.97, 0],
                      ],
                      v: [
                        [807.5, 0],
                        [0, 807.5],
                        [-807.5, 0],
                        [0, -807.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 50, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: { a: 0, k: 0, ix: 1 },
                  e: {
                    a: 0,
                    k: 0,
                    ix: 2,
                    x: "var $bm_rt;\nvar delay;\ndelay = thisComp.layer('Mini Circle Control').effect('Delay')('Slider').valueAtTime(2.375);\n$bm_rt = thisComp.layer('Mini Circle Control').effect('Animation')('Slider').valueAtTime($bm_sub(time, delay));",
                  },
                  o: { a: 0, k: -160, ix: 3 },
                  m: 1,
                  ix: 3,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [2226.132, 827.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "19",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 19,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [2226.133, 2442.5], ix: 2 },
              a: { a: 0, k: [2226.133, 2442.5], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Mini Circles",
          np: 19,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 719.91991991992,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
