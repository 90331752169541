import React from "react";
import constants from "Constants";

export default function NotAdmin() {
  return (
    <div className="w-100">
      <div
        className="d-flex flex-column align-items-center justify-content-center "
        style={{ height: window.innerHeight - constants.navHeight }}
      >
        <div className="bg-danger rounded text-light p-2 text-center ">
          You do not have permission to access this page. For administrative access, please contact
          me at cgp27@byu.edu
        </div>
      </div>
    </div>
  );
}
