import Definition from "components/concepts/templates/Definition";
import Image from "../templates/Image";
import History from "../templates/History";
import RelatedFrameworks from "../templates/RelatedFrameworks";
import { Concepts } from "lib/data/Concepts";
import ExampleContainer from "../templates/ExampleContainer";
import Example from "../templates/Example";

const CageImg = require("lib/img/concepts/CAGE_framework.png");

export default function Cage() {
  return (
    <div>
      <Definition>
        <p>
          When contemplating international expansion, business leaders often have difficulty
          predicting the differences between their home countries and foreign expansion
          opportunities. By analyzing the CAGE distance between the two markets, they can understand
          the potential challenges and opportunities associated with international expansion. A
          larger CAGE distance would indicate greater challenges while a smaller CAGE distance could
          indicate easier entries.
        </p>
        <p>
          <b>(C) Culture</b> - referring to difference in language, religion, traditions, values and
          social norms. These differences significantly impact consumer preferences and norms.{" "}
        </p>
        <p>
          <b>(A) Administration</b> - relating to differences of legal systems, political
          ideologies, regulations and bureaucracies. These can affect operating procedures,
          taxation, IP & labor laws.
        </p>
        <p>
          <b>(G) Geography</b> - representing physical distance, infrastructure, time zones, &
          climate. These could impact distribution, communication and logistics.{" "}
        </p>
        <p>
          <b>(E) Economy</b> - meaning the difference of income levels economic development, and
          currency exchange rates. This affects consumer buying power, production costs and overall
          market attractiveness.
        </p>
        <p>
          Example: Spotify. Expanding globally, Spotify adapted their music catalogue and user
          interface to different languages and cultural preferences (cultural factor). They complied
          with complex licensing and copyright regulations in each country (administrative factor).
          Geographical considerations involved tailoring streaming quality based on internet
          infrastructure and adapting marketing strategies to different time zones. Spotify also
          adjusted subscription fees based on local economic realities.
        </p>
      </Definition>
      <Image src={CageImg} alt="" />
      <ExampleContainer>
        <Example header="Domino's Pizza">
          When entering India, Domino's undertook extensive analysis using the CAGE framework. They
          adapted their menu to accommodate vegetarian preferences (pizza with paneer) and navigated
          complex administrative regulations regarding food safety and franchising. Understanding
          the geographical distances and economic limitations also led them to adjust delivery
          pricing and store formats.
        </Example>
        <Example header="Netflix">
          Their expansion into China involved adapting their content library and interface to comply
          with censorship regulations (administrative factor). They considered cultural differences
          in content preferences and partnered with local companies to address geographical and
          logistical challenges. Understanding economic limitations, they introduced tiered
          subscription plans.
        </Example>
        <Example header="Uber">
          Entering new markets involved tailoring their app to different languages and cultural
          norms (cultural factor). They navigated licensing and regulatory hurdles in each country
          (administrative factor). Uber utilized geographical mapping and adjusted payment methods
          based on local preferences. Additionally, they considered economic differences by offering
          different service tiers and pricing structures.
        </Example>
      </ExampleContainer>
      <History>
        <p>
          The CAGE framework for understanding distance in international business was developed by{" "}
          <b>Pankaj Ghemawat</b>, a professor at IESE Business School in Barcelona, Spain. Its debut
          came in his 1986 paper titled "Distance Despite Borders: How Firms Manage in the World
          Economy."
        </p>
        <p>Here's a breakdown of its key points:</p>
        <p>
          <b>1986:</b> Initial development and publication. Ghemawat proposed the CAGE framework as
          an alternative to traditional models that primarily focused on geographical distance. He
          argued that cultural, administrative, and economic differences also significantly impact
          the "distance" between markets, influencing operational challenges and opportunities.
        </p>
        <p>
          <b>1991:</b> Further refinement. Ghemawat expanded on the framework in his book
          "Borderless Management: Traversing the Global Labyrinth." He provided deeper explanations
          for each of the CAGE dimensions and demonstrated its application in analyzing different
          international business scenarios.
        </p>
        <p>
          <b>1990s-2000s:</b> Growing recognition and application. The CAGE framework gained
          traction among academics and practitioners in international business. Its simplicity and
          flexibility allowed companies to assess market potential and develop more nuanced
          strategies for global expansion.
        </p>
        <p>
          <b>2010s-present:</b> Continued relevance and evolution. While newer frameworks have
          emerged, the CAGE model remains a valuable tool for understanding international business
          complexities. Scholars continue to analyze and refine it, emphasizing its adaptability to
          various industries and market contexts.
        </p>
      </History>
      <p>
        <b>Additionally:</b>
      </p>
      <ul>
        <li>
          The framework has been empirically tested and validated through research studies,
          demonstrating its effectiveness in predicting the challenges and opportunities associated
          with international expansion.
        </li>
        <li>
          Its focus on multiple dimensions of "distance" encourages companies to move beyond solely
          geographical considerations and develop more comprehensive global strategies.
        </li>
      </ul>
      <p>
        It's important to note that the CAGE framework is not a rigid formula, but rather a starting
        point for analysis. Other factors like political stability, technological advancements, and
        competitive landscape also play crucial roles in international business decisions. The
        framework's true strength lies in its ability to prompt a holistic evaluation of potential
        markets and guide companies towards making informed expansion choices.
      </p>
      <RelatedFrameworks concepts={[Concepts.TripleA]} unfinishedConcepts={["Pest Analysis"]} />
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}
