import { TValidateTokenRes, TErrorRes } from "@sharedTypes/res.type";
import { TValidateTokenReq } from "@sharedTypes/req.type";
import constants from "Constants";
import axios from "axios";
import missingParametersError from "api/Error";

export async function ValidateToken() {
  const apiUrl = constants.apiUrl + "/auth/ValidateToken";

  const body: TValidateTokenReq = {
    userId: localStorage.getItem("userId"),
    token: localStorage.getItem("token"),
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TValidateTokenRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}
