import React from "react";

export default function History({ children }: { children: any }) {
  return (
    <div className="mt-5">
      <h3>History</h3>
      <hr />
      <div>{children}</div>
    </div>
  );
}
