import React from "react";
const Logo = require("lib/img/Logo.svg").default;

export default function LogoSvg({
  className,
  style,
  filter = "",
}: {
  className?: string;
  style?: React.CSSProperties;
  filter?: string;
}) {
  return <img src={Logo} className={className} alt="" style={{ ...style, filter }} />;
}
