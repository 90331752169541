import { TFocuses } from "types/JsonType";

export const Focuses = {
  FoundationInPurpose: {
    url: "/focus/FoundationInPurpose",
    filePath: "components/focuses/pages/FoundationInPurpose",
    id: "FoundationInPurpose",
    name: "Foundation in Purpose",
    question: "Why you are in business",
    color: "#00FF0020",
    num: 1,
    letter: "F",
    summary:
      "Your company's purpose is more than just a tagline—it's the driving force behind resilience, value creation, and meaningful impact.  Authentic purpose attracts loyal customers, engaged employees, and supportive investors.  Today, where employees seek meaning and consumers align with purpose-driven brands, its importance is paramount.  Studies show that employees connected to their organization's purpose report higher resilience, better health, and increased engagement.  Join us on this pathway to define and integrate purpose effectively, driving culture, performance, and success.  What you will do and what will you not do.",
    imgSrc: require("lib/img/focuses/FoundationInPurpose.jpg"),
    type: "Focus",
  },
  OperatingArena: {
    url: "/focus/OperatingArena",
    filePath: "components/focuses/pages/OperatingArena",
    id: "OperatingArena",
    name: "Operating Arena",
    question: "Who is your target audience",
    color: "#00FFFF20",
    num: 2,
    letter: "O",
    summary:
      "Your company's Operating Arena defines where and how you compete, focusing on identifying the most effective areas within the industry where your business can excel. This strategic analysis requires understanding of industry trends, competitive dynamics, and customer needs. By pinpointing where your unique capabilities align with market opportunities, you can position your business for sustainable success. The Operating Arena is essential for navigating market complexities and seizing competitive advantages. Embrace this pathway to strategically locate your business in the marketplace, ensuring you leverage strengths while avoiding overextended commitments. What will your strategic focus be, and what opportunities will you forgo?",
    imgSrc: require("lib/img/focuses/OperatingArena.jpg"),
    type: "Focus",
  },
  CustomerUnmetNeeds: {
    url: "/focus/CustomerUnmetNeeds",
    filePath: "components/focuses/pages/CustomerUnmetNeeds",
    id: "CustomerUnmetNeeds",
    name: "Customer Unmet Needs",
    question: "How to offer something no one else does",
    color: "#FF00FF20",
    num: 3,
    letter: "C",
    summary:
      "Every customer has needs (and wants) that must be met. Of all the needs your customers have, which ones are currently unaddressed or only poorly addressed? Businesses will stand out from the competition if they can understand what their customers are still trying to accomplish, what about existing solutions frustrates customers, and how they can offer unique solutions to both better meet these needs and reduce or eliminate the elements that frustrate customers.",
    imgSrc: require("lib/img/focuses/CustomerUnmetNeeds.jpg"),
    type: "Focus",
  },
  UnifiedBusinessModel: {
    url: "/focus/UnifiedBusinessModel",
    filePath: "components/focuses/pages/UnifiedBusinessModel",
    id: "UnifiedBusinessModel",
    name: "Unified Business Model",
    question: "How to deliver value to customers",
    color: "#FFFF0020",
    num: 4,
    letter: "U",
    summary:
      "Once the customers have been identified and the unique value proposition has been developed, it's time to look internally at how your business operates. How do the day-to-day operating activities support your targeted strategy? This includes key activities, key resources, and other factors across the organization that allow you to deliver value to customers. By developing these daily activities in alignment with your intended strategy, you can determine what activities need to be done and, more importantly, what should not be done.",
    imgSrc: require("lib/img/focuses/UnifiedBusinessModel.jpg"),
    type: "Focus",
  },
  SafeguardsAgainstImitation: {
    url: "/focus/SafeguardsAgainstImitation",
    filePath: "components/focuses/pages/SafeguardsAgainstImitation",
    id: "SafeguardsAgainstImitation",
    name: "Safeguards Against Imitation",
    question: "How to prevent imitation",
    color: "#FF000020",
    num: 5,
    letter: "S",
    summary:
      "In speaking to venture capitalists, they will readily admit (whether its true or not) that they have heard every new business idea 10 or more times.  Unfortunately, its not enough to have a great idea.  Because of the capitalist market, competitors will see your good idea and try to copy it and monopolize on it.  Safeguards describe the protective barriers to these changes and attacks to ensure youir company stays relevant and sales remain strong.  If not, there is a bigger fish with more resources can come and take it all away.",
    imgSrc: require("lib/img/focuses/SafeguardsAgainstImitation.jpg"),
    type: "Focus",
  },
} as const satisfies TFocuses;

export default Focuses as TFocuses;
