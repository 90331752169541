import { TVerifyOtpReq } from "@sharedTypes/req.type";
import { TErrorRes, TVerifyOtpRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function VerifyOtp(email: string, otp: string) {
  const apiUrl = constants.apiUrl + "/email/verifyotp";

  const body: TVerifyOtpReq = {
    email,
    otp,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TVerifyOtpRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}
