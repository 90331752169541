import { Link } from "react-router-dom";
import Focuses from "lib/data/Focuses";
import constants from "Constants";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import MasterSidebar from "components/navigation/MasterSidebar";
import SidebarPage from "../templates/SidebarPage";
import { TFocus } from "types/JsonType";
import Accordion from "react-bootstrap/Accordion";
import useElementSize from "hooks/useElementSize";
import HorizontalAccordion from "components/templates/HorizontalAccordion";

function Header() {
  const [headerContainer, { width }] = useElementSize();
  const screenSmall = width < 800;
  return (
    <div className=" my-4">
      <div className="text-center mb-4">
        <div className="display-3 ">
          Strategy <span className="text-grey-red">FOCUS</span>
        </div>
        <div className="lead fs-5 mt-1">Zoom in on progress</div>
      </div>

      <div className="container-fluid p-0">
        <div ref={headerContainer} className="row">
          <div className={` ${screenSmall ? "col-12" : "col-7"}`}>
            <p>
              Business can be very complicated, due to its countless moving parts. It takes energy,
              focused in the right areas, to make an impact. Just like a magnifying glass focuses
              light to create energy in the form of heat, there are key elements of business
              strategy that, if focused on, create true business results.
            </p>
            <div className="ms-3">
              {Object.values(Focuses).map(function (focus, i) {
                return (
                  <div key={focus.id} className="d-flex align-items-center">
                    <Link to={focus.url} className="text-body no-underline">
                      <span className="fs-3 text-grey-red h-100">{focus.letter}</span>
                      <span className="underline-on-hover">
                        <b>{focus.name.slice(1)}</b>: {focus.question}
                      </span>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={`${
              screenSmall ? "col-12" : "col-5"
            } text-center d-flex align-items-center justify-content-center`}
          >
            <div className="w-100 p-1 " style={{ height: 300 }}>
              <img
                src={require("lib/img/Magnifying_Glass.png")}
                className="h-100 w-100 object-fit-cover"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AccordionHeader({ focus }: { focus: TFocus }) {
  return (
    <div id="accordion-header" className="text-center mt-4">
      <img src={require(`lib/img/letters/${focus.letter}.png`)} height={25} alt="" />
    </div>
  );
}

function AccordionContent({ focus }: { focus: TFocus }) {
  return (
    <div>
      <div className="px-3">
        <div className="lead text-center mt-2">
          <Link to={focus.url} className="text-body no-underline underline-on-hover">
            {focus.name}
          </Link>
        </div>
        <div className="my-3">{focus.summary}</div>
      </div>
      <img src={focus.imgSrc} className="w-100 opacity-0" alt="" />
      <Link to={focus.url}>
        <img
          src={focus.imgSrc}
          className="position-absolute bottom-0 end-0 object-fit-cover w-100"
          alt=""
        />
      </Link>
    </div>
  );
}

function FocusVerticalAccordion() {
  return (
    <Accordion defaultActiveKey={Object.keys(Focuses)[0]}>
      {Object.values(Focuses).map((focus) => {
        return (
          <Accordion.Item eventKey={focus.id} key={focus.id}>
            <Accordion.Header>
              <div className="text-center w-100 mb-4">
                <AccordionHeader focus={focus} />
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="position-relative">
                <AccordionContent focus={focus} />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
}

function FocusHorizontalAccordion() {
  return (
    <HorizontalAccordion.Container>
      {Object.values(Focuses).map((focus, i) => {
        return (
          <HorizontalAccordion.Tab key={focus.id} i={i}>
            <HorizontalAccordion.Header>
              <AccordionHeader focus={focus} />
            </HorizontalAccordion.Header>
            <HorizontalAccordion.Content>
              <AccordionContent focus={focus} />
            </HorizontalAccordion.Content>
          </HorizontalAccordion.Tab>
        );
      })}
    </HorizontalAccordion.Container>
  );
}

function FocusPreview() {
  const [contentContainer, { width }] = useElementSize();
  const screenSmall = width < 600;

  return (
    <div ref={contentContainer}>
      {screenSmall ? <FocusVerticalAccordion /> : <FocusHorizontalAccordion />}
    </div>
  );
}

export default function FocusHome() {
  SetDocumentTitle("FOCUS Home");

  return (
    <SidebarPage.Page>
      <SidebarPage.Sidebar>
        <MasterSidebar />
      </SidebarPage.Sidebar>
      <SidebarPage.Content>
        <div className="w-100 px-3">
          <div className="container mb-5" style={{ maxWidth: constants.maxPageWidth }}>
            <Header />
            <FocusPreview />
          </div>
        </div>
      </SidebarPage.Content>
    </SidebarPage.Page>
  );
}
