import { TDeleteP5FReq } from "@sharedTypes/req.type";
import { TErrorRes, TDeleteP5FRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function DeleteP5F(saveId: number) {
  const apiUrl = constants.apiUrl + "/tools/p5f/DeleteP5F";

  const body: TDeleteP5FReq = {
    token: localStorage.getItem("token"),
    userId: localStorage.getItem("userId"),
    saveId,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TDeleteP5FRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}
