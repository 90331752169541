import Loading from "components/LoadingPage";
import NotAdmin from "components/auth/NotAdmin";
import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import UserContext from "context/UserContext";

export function AdminLogin() {
  let context = useOutletContext();
  let return_url = window.location.pathname;
  const { loginStatus, user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (loginStatus === "invalid") {
      setTimeout(() => {
        if (mounted) navigate("/login?return_url=" + return_url);
      }, 300);
    }
    return () => {
      mounted = false;
    };
  });

  if (loginStatus === "invalid") return <Loading>Redirecting...</Loading>;
  else if (loginStatus === "loading") return <Loading>Verifying Login...</Loading>;
  else if (loginStatus === "logged in") {
    if (user.isAdmin) return <Outlet context={context} />;
    return <NotAdmin />;
  }
}

export function UserLogin() {
  let context = useOutletContext();
  const navigate = useNavigate();
  let return_url = window.location.pathname;
  // const { loginStatus } = useContext(UserContext);
  let loginStatus = "logged in";

  useEffect(() => {
    let mounted = true;
    if (loginStatus === "invalid") {
      setTimeout(() => {
        if (mounted) navigate("/login?return_url=" + return_url);
      }, 300);
    }
    return () => {
      mounted = false;
    };
  });

  if (loginStatus === "loading") return <Loading>Verifying Login...</Loading>;
  else if (loginStatus === "invalid") return <Loading>Redirecting to Login...</Loading>;
  else if (loginStatus === "logged in") return <Outlet context={context} />;
}
