import React from "react";
import constants from "Constants";

type TLoadingProps = {
  children?: React.ReactNode;
};

export default function LoadingPage({ children }: TLoadingProps) {
  return (
    <div
      className="d-flex w-100 flex-column align-items-center justify-content-center"
      style={{ height: window.innerHeight - constants.navHeight }}
    >
      {children}
      <div className="spinner-border text-primary mt-2" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
