import React from "react";

export default function Definition({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <h3>Description/Definition</h3>
      <hr />
      {children}
    </div>
  );
}
