import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Focuses from "lib/data/Focuses";
import Pathways from "lib/data/Pathways";
import { defaultFocus, TFocus } from "types/JsonType";
import constants from "Constants";
import SidebarPage from "components/templates/SidebarPage";

import MasterSidebar from "components/navigation/MasterSidebar";
import { Link } from "react-router-dom";
import BreadcrumbsAndArrows from "components/navigation/BreadcrumbsAndArrows";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import useElementSize from "hooks/useElementSize";
import Error from "components/templates/Error";

type TFocusContext = {
  focus: TFocus;
};

const FocusContext = createContext<TFocusContext>({
  focus: defaultFocus,
});

function Title() {
  const { focus } = useContext(FocusContext);
  return (
    <div className="text-center w-100 my-4">
      <div className="display-4">
        <span className="text-grey-red display-2"> {focus.letter}</span>
        {focus.name.slice(1)}
      </div>
      <div className="lead fs-5">{focus.question}</div>
    </div>
  );
}

function FocusIntro() {
  const { focus } = useContext(FocusContext);

  const [ImportedComponent, setImportedComponent] = useState(null) as any;

  useEffect(() => {
    async function ImportComponents(filePath: string) {
      setImportedComponent(null);
      await import(`${filePath}`).then((module) => {
        const AnotherComponent = module.default;
        setImportedComponent(<AnotherComponent />);
      });

      // import all adjacent focus components. This dramatically improves the load time of the pages.
      Object.values(Focuses).forEach((page) => {
        import(`${page.filePath}`);
      });
    }

    ImportComponents(focus.filePath);
  }, [focus.filePath]);

  return (
    <div>
      <Title />
      <div className="w-100 d-flex">
        <div className="mx-auto w-p">
          {ImportedComponent}
          <div className="mt-4">
            <h5>Pathways:</h5>
            <ul>
              {Object.values(Pathways).map(function (pathway, i) {
                if (pathway.focus !== focus.id) return null;
                return (
                  <li key={pathway.id}>
                    <Link to={pathway.url} className="no-underline underline-on-hover text-body">
                      <b>{pathway.name}</b> - {pathway.question}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function PathwayGraphic() {
  const { focus } = useContext(FocusContext);
  const FocusPathways = Object.values(Pathways).filter((pathway) => pathway.focus === focus.id);
  const [graphicContainer, { width }] = useElementSize();
  const elementSmall = width < 750;
  return (
    <div className="w-100 d-flex">
      <div className="mx-auto bg-tan-3 rounded mb-5 px-4 py-3" ref={graphicContainer}>
        {FocusPathways.map((pathway, i) => {
          const isEven = i % 2 === 0;

          const summary = (
            <div className={elementSmall ? "col-12" : "col-8"}>
              <div>{pathway.summary} </div>
            </div>
          );
          const img = (
            <div className={elementSmall ? "col-12" : "col-4"}>
              <Link to={pathway.url} className="text-body no-underline ">
                <img src={pathway.imgSrc} alt="" className="w-100 shadow-on-hover" />
              </Link>
            </div>
          );
          return (
            <div key={pathway.id} className="container ">
              <div className="d-flex justify-content-center">
                <div className="text-center mb-3">
                  <div className="fs-5 fw-bold">{pathway.name}</div>
                  <hr className="my-0" />
                  <div className="fs-6">{pathway.question}</div>
                </div>
              </div>

              <div className="row">
                {isEven ? (
                  <>
                    {img}
                    {summary}
                  </>
                ) : (
                  <>
                    {summary}
                    {img}
                  </>
                )}
                <div className="d-flex justify-content-center mt-3">
                  {i !== FocusPathways.length - 1 && (
                    <img
                      src={require("lib/img/pathwaysArrow.svg").default}
                      alt=""
                      className="w-70 "
                      style={{
                        transform: isEven ? "" : "scaleX(-1)",
                        filter: constants.colors.tan2CssFilter,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default function FocusRoot() {
  const focusId = useParams().focusId;
  if (!focusId) return <Error message={"404. Page not found"} />;
  const focus = Focuses[focusId];
  if (!focus) return <Error message={"404. Page not found"} />;

  SetDocumentTitle(focus.name);

  return (
    <FocusContext.Provider value={{ focus }}>
      <SidebarPage.Page>
        <SidebarPage.Sidebar>
          <MasterSidebar />
        </SidebarPage.Sidebar>
        <SidebarPage.Content>
          <BreadcrumbsAndArrows focus={focus} />
          <div className="d-flex justify-content-center mb-5 mx- mx-md-4">
            <div className="w-100" style={{ maxWidth: constants.maxPageWidth }}>
              <FocusIntro />
              <PathwayGraphic />
            </div>
          </div>
        </SidebarPage.Content>
      </SidebarPage.Page>
    </FocusContext.Provider>
  );
}
