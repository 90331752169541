import Categories from "lib/data/Categories";
import { TCategory, TConcepts, TPathways, TFocus, TFocuses } from "types/JsonType";
import ConceptsData from "lib/data/Concepts";
import PathwaysData from "lib/data/Pathways";
import FocusData from "lib/data/Focuses";

type TConceptView = {
  id: string;
  url: string;
  name: string;
  category: TCategory;
  summary: string;
  essential: boolean;
};

type TFullConceptsView = {
  [key: string]: TConceptView;
};

export type TPathwayView = {
  id: string;
  question: string;
  focus: string;
  name: string;
  imgSrc: string;
  url: string;
  concepts: {
    [key: string]: TConceptView;
  };
};

type TFullPathwaysView = {
  [key: string]: TPathwayView;
};

export type TFocusView = TFocus & {
  pathways: {
    [key: string]: TPathwayView;
  };
};

export type TFullFocusView = {
  [key: string]: TFocusView;
};

const focusesList = Object.keys(FocusData);
const pathwaysList = Object.keys(PathwaysData);
const conceptsList = Object.keys(ConceptsData);

export function GetConceptsView(Concepts: TConcepts = ConceptsData) {
  const ConceptsView: TFullConceptsView = {};

  conceptsList.forEach((conceptId) => {
    ConceptsView[conceptId] = {
      ...Concepts[conceptId],
      category: Categories[Concepts[conceptId].category],
    };
  });
  return ConceptsView;
}

export function GetPathwaysView(
  Pathways: TPathways = PathwaysData,
  Concepts: TConcepts = ConceptsData
) {
  const PathwaysView: TFullPathwaysView = {};
  const ConceptsView = GetConceptsView(Concepts);

  pathwaysList.forEach((pathwayId) => {
    PathwaysView[pathwayId] = {
      ...Pathways[pathwayId],
      concepts: {},
    };
    Pathways[pathwayId].concepts.forEach((conceptId) => {
      PathwaysView[pathwayId].concepts[conceptId] = ConceptsView[conceptId];
    });
  });
  return PathwaysView;
}

export function GetFocusesView(
  Focuses: TFocuses = FocusData,
  Pathways: TPathways = PathwaysData,
  Concepts: TConcepts = ConceptsData
) {
  const FocusesView: TFullFocusView = {};
  const PathwaysView = GetPathwaysView(Pathways, Concepts);

  focusesList.forEach((focusId) => {
    FocusesView[focusId] = {
      ...Focuses[focusId],
      pathways: {},
    };
  });

  pathwaysList.forEach((pathwayId) => {
    const pathway = Pathways[pathwayId];
    FocusesView[pathway.focus].pathways[pathwayId] = PathwaysView[pathwayId];
  });

  return FocusesView;
}
