export default function Error({ message }: { message: string }) {
  return (
    <div
      className="w-100 d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: window.innerHeight - 100 }}
    >
      <div>Error {message}</div>
      <div>
        Contact support at <a href="mailto:cgp27@byu.edu">cgp27@byu.edu</a> to report a bug.
      </div>
    </div>
  );
}
