import constants from "Constants";
import React from "react";

export default function Image({ src, alt }: { src: string; alt: string }) {
  return (
    <div className="d-flex w-100 justify-content-center">
      <img className="w-100" src={src} alt={alt} style={{ maxWidth: constants.contentWidth }} />
    </div>
  );
}
