import { Link } from "react-router-dom";
import { TConcept } from "types/JsonType";

export default function RelatedFrameworks({
  concepts = [],
  unfinishedConcepts = [],
}: {
  concepts?: TConcept[];
  unfinishedConcepts?: string[];
}) {
  return (
    <div className="mt-5  ">
      <h3>Related Frameworks</h3>
      <hr />
      <ul>
        {concepts.map((concept, index) => (
          <li key={index}>
            <Link to={concept.url}>{concept.name}</Link>
          </li>
        ))}
        {unfinishedConcepts.map((concept, index) => (
          <li key={index}>
            <Link to="#">{concept}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
