import { TLoginAttemptReq } from "@sharedTypes/req.type";
import { TErrorRes, TLoginAttemptRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function LoginAttempt(email: string, password: string) {
  const apiUrl = constants.apiUrl + "/auth/loginattempt";

  const body: TLoginAttemptReq = {
    email,
    password,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TLoginAttemptRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}
