export default function PasswordValidator(password: string) {
  let errors = [];
  if (password.length < 8) {
    errors.push("8 characters");
  }
  if (!password.match(/[a-z]/)) {
    errors.push("1 lowercase letter");
  }
  if (!password.match(/[A-Z]/)) {
    errors.push("1 uppercase letter");
  }
  if (!password.match(/[0-9]/)) {
    errors.push("1 number");
  }

  return errors;
}
