import { useContext, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OtpVerifier from "components/auth/OtpVerifier";
import passwordValidator from "utils/PasswordValidator";
import { SetDocumentTitle } from "utils/SetDocumentTitle";
import { CreateUser } from "api/auth/CreateUser";
import UserContext from "context/UserContext";
const RegisterBG = require("lib/img/loginBG.png");

export default function Register() {
  SetDocumentTitle("Register");

  const [errorMessage, setErrorMessage] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const navigate = useNavigate();
  let passwordsMatch = password1 === password2;
  let password1Errors = passwordValidator(password1);

  const searchParams = useSearchParams()[0];
  let return_url = searchParams.get("return_url") ?? "/";

  const { loginStatus } = useContext(UserContext);
  if (loginStatus === "logged in") {
    navigate(return_url);
  }

  // handle registration submit
  async function RegistrationAttemptHandler(e: any) {
    e.preventDefault();
    const firstName = e.target["fname"].value;
    const lastName = e.target["lname"].value;
    const email = e.target["email"].value;
    const password = e.target["password1"].value;

    const data = await CreateUser(firstName, lastName, email, password);

    if (data.status === "success") {
      setEmailVerificationRequired(true);
      setUserEmail(email);
      document.getElementById("otp-anchor")?.scrollIntoView();
    } else {
      setErrorMessage(data.message);
      document.getElementById("error-anchor")?.scrollIntoView();
      console.error(data);
    }
  }

  if (emailVerified) {
    navigate("/login/?return_url=" + return_url + "&verified=true");
  }

  return (
    <div className="w-100">
      <div className="" style={{ minHeight: window.innerHeight }}>
        <div>
          <div
            id="login-bg"
            className="d-flex flex-wrap justify-content-between position-relative"
            style={{
              minHeight: window.innerHeight,
              objectPosition: "right",
            }}
          >
            <img
              className="position-fixed object-fit-cover h-100 w-100 z-n1"
              src={RegisterBG}
              alt="strategy unlocked"
              style={{ opacity: "0.5", objectPosition: "right" }}
            />
            {/* input card */}
            <div className=" p-2 p-md-5 w-100" style={{ maxWidth: 600 }}>
              <div className="d-block p-4 w-100 shadow bg-body mx-auto mx-md-0 rounded">
                <div className="">
                  <h1> Nice to Meet You!</h1>
                  <div className="position-relative">
                    <div className="position-absolute" style={{ top: -100 }} id="error-anchor" />
                  </div>
                  {errorMessage && (
                    <div className=" my-4">
                      <div className="alert alert-danger">
                        <button
                          type="button"
                          className="btn-close position-absolute top-0 end-0 m-2"
                          aria-label="Close"
                          onClick={() => setErrorMessage("")}
                        ></button>
                        <div>{errorMessage}</div>
                      </div>
                    </div>
                  )}

                  <div className="lead d-flex flex-wrap fs-6 mb-2">
                    <Link to={"/login/?return_url=" + return_url}>Back to login</Link>
                  </div>
                </div>
                <div>
                  <form onSubmit={RegistrationAttemptHandler} className="form-control">
                    <div className="m-2 my-4">
                      <label htmlFor="email">First Name</label>
                      <input
                        id="fname"
                        name="fname"
                        className="form-control"
                        type="text"
                        autoComplete="given-name"
                        required
                      />
                    </div>
                    <div className="m-2 my-4">
                      <label htmlFor="email">Last Name</label>
                      <input
                        id="lname"
                        name="lname"
                        className="form-control"
                        type="text"
                        autoComplete="family-name"
                        required
                      />
                    </div>
                    <div className="m-2 my-4">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        name="email"
                        className="form-control"
                        type="email"
                        autoComplete="email"
                        required
                      />
                    </div>
                    <div className="m-2 my-4">
                      <label htmlFor="password1" className="">
                        <div>Password</div>
                      </label>

                      <input
                        id="password1"
                        name="password1"
                        className="form-control"
                        type="password"
                        autoComplete="new-password"
                        required
                        onChange={(e: any) => setPassword1(e.target.value)}
                      />
                    </div>
                    {password1Errors.length > 0 && (
                      <div className="m-2 my-4">
                        Your password must have at least:
                        <ul>
                          {password1Errors.map((error) => (
                            <li key={error}>{error}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                    <div className="m-2 my-4">
                      <label htmlFor="password2" className="">
                        <div>Confirm Password</div>
                      </label>

                      <input
                        id="password2"
                        name="password2"
                        className="form-control"
                        type="password"
                        autoComplete="new-password"
                        required
                        onChange={(e: any) => setPassword2(e.target.value)}
                        style={passwordsMatch ? {} : { border: "1px solid red" }}
                      />
                      {!passwordsMatch && <span className="text-danger">Passwords must match</span>}
                    </div>

                    <div className="d-flex ">
                      <input
                        type="submit"
                        className="w-100 btn btn-primary m-2 mt-3"
                        value={"Create Account"}
                        disabled={!passwordsMatch || password1Errors.length > 0}
                      />
                    </div>
                  </form>

                  <OtpVerifier
                    email={userEmail}
                    setEmailVerified={setEmailVerified}
                    showVerifier={emailVerificationRequired && !emailVerified}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: 600, maxWidth: 600 }}>
              <div className="text-secondary bg-white p-3 m-5 rounded">
                Development notes:
                <ul>
                  <li>
                    I am going to be deleting and restarting the database over and over again, so if
                    you create an account, it will likely be deleted within a few days. For now,
                    just use the test account. It will not be deleted.
                  </li>
                  <li className="text-success">
                    Email verificaton works now (or at least I hope it does)!! <b>Give it a try!</b>{" "}
                    and let me know if it works.
                  </li>
                  <li>
                    Until I get the payment system figured out, all emails will be free instead of
                    only BYU emails. Once I figure out how the payment system is set up, I will send
                    byu students to email verification and everyone else to payment.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
