import { TTools } from "types/JsonType";

export const Tools = {
  PortersFiveForcesCalculator: {
    url: "/user/tool/PortersFiveForcesCalculator",
    name: "Porter's Five Forces Calculator",
    summary:
      "Analyze the attractiveness of an industry with the Porter's Five Forces tool. Use either the simple calculator to get a ballpark estimate in seconds, or use the advanced calculator for an in depth answer you can trust.",
    imgSrc: require("lib/img/tools/PortersFiveForces.jpg"),
    filePath: "components/tools/pages/PortersFiveForcesCalculator",
    type: "Tool",
  },
  ThreeHorizonsCalculator: {
    url: "/user/tool/ThreeHorizonsCalculator",
    name: "Three Horizons Calculator",
    summary: "DESCRIPTION",
    imgSrc: require("lib/img/tools/ThreeHorizons.jpg"),
    type: "Tool",
    filePath: "components/tools/pages/ThreeHorizonsCalculator",
  },
  StrategyCanvasCalculator: {
    url: "/user/tool/StrategyCanvasCalculator",
    name: "Strategy Canvas Calculator",
    summary: "DESCRIPTION",
    imgSrc: require("lib/img/tools/StrategyCanvas.jpg"),
    type: "Tool",
    filePath: "components/tools/pages/StrategyCanvasCalculator",
  },
} as const satisfies TTools;

export default Tools as TTools;
