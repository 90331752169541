import constants from "Constants";
import useWindowSize from "hooks/useWindowSize";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import BootstrapMenu from "components/bootstrap_icons/Menu";

function Content({ children }: { children?: any }) {
  return (
    <div id="sidebar-page-content" className="w-100">
      {children}
    </div>
  );
}

function Sidebar({ children }: { children?: any }) {
  type TSidebarType = "expanded" | "small" | "hidden";
  const { sidebarCollapsed, setSidebarCollapsed } = useContext(SidebarContext);
  let sidebarType: TSidebarType;
  const { width } = useWindowSize();
  const screenSmall = width < 768;

  if (screenSmall) {
    sidebarType = sidebarCollapsed ? "hidden" : "expanded";
  } else {
    sidebarType = sidebarCollapsed ? "small" : "expanded";
  }

  let sidebarWidth;

  if (sidebarType === "expanded") {
    sidebarWidth = constants.sidebarExpandedWidth + "em";
  } else if (sidebarType === "small") {
    sidebarWidth = constants.sidebarCollapsedWidth + "em";
  } else {
    sidebarWidth = "0";
  }

  function CollapseHandler(e: any) {
    e.stopPropagation();
    setSidebarCollapsed((sidebarCollapsed) => !sidebarCollapsed);
  }

  return (
    <div
      id="sidebar"
      className={`h-100 bg-white ${(!sidebarCollapsed || !screenSmall) && "border-end"} ${
        screenSmall ? "position-fixed z-3 " : "position-relative "
      }`}
      style={{
        maxWidth: sidebarWidth,
        width: sidebarWidth,
        minWidth: sidebarWidth,
        transition: "all 0.3s",
      }}
    >
      {/* menu button when hidden */}
      {sidebarType === "hidden" && (
        <div className="overflow-visible no-word-break">
          <div
            className="d-flex justify-content-center"
            style={{ width: constants.sidebarCollapsedWidth + "em" }}
          >
            <div
              className="mt-2"
              style={{ width: constants.sidebarCollapsedWidth / 2 + "em", cursor: "pointer" }}
              onClick={CollapseHandler}
            >
              <BootstrapMenu />
            </div>
          </div>
        </div>
      )}
      {/* collapsed hover bg overlay */}
      {sidebarCollapsed && (
        <div
          className="position-absolute w-100 h-100 sidebar-hover z-2"
          style={{ transition: "all 0.3s", cursor: "pointer" }}
          onClick={(e) => {
            if (sidebarCollapsed) CollapseHandler(e);
          }}
        ></div>
      )}
      {/* sidebar content */}
      <div
        className="position-sticky overflow-y-auto overflow-x-hidden"
        style={{
          top: "60px",
          maxHeight: window.innerHeight - constants.navHeight,
          scrollbarWidth: "none",
        }}
      >
        <div
          className="position-relative"
          style={{
            maxWidth: constants.sidebarExpandedWidth + "em",
            width: constants.sidebarExpandedWidth + "em",
            minWidth: constants.sidebarExpandedWidth + "em",
            overflowX: "hidden",
          }}
        >
          <div
            className="position-absolute end-0 p-2"
            onClick={CollapseHandler}
            style={{ cursor: "pointer" }}
          >
            <button type="button" className="btn-close" aria-label="Close" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

type TSidebarContext = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SidebarContext = createContext<TSidebarContext>({
  sidebarCollapsed: false,
  setSidebarCollapsed: () => {},
});

type TPageProps = {
  children?: any;
  sidebarDefaultCollapsed?: boolean;
};

function Page({ children, sidebarDefaultCollapsed = window.innerWidth < 768 }: TPageProps) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(sidebarDefaultCollapsed);

  return (
    <SidebarContext.Provider value={{ sidebarCollapsed, setSidebarCollapsed }}>
      <div className="d-flex w-100">{children}</div>
    </SidebarContext.Provider>
  );
}

const SidebarPage = {
  Page,
  Sidebar,
  Content,
};

export default SidebarPage;
