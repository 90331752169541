import React from "react";

export default function AnalyzeFirmActivitiesPathway() {
  return (
    <div>
      <h1>Key Activities</h1>
      <p>
        Key activities encompass essential tasks crucial for delivering value to customers. They're
        the core operations that drive customer satisfaction and are pivotal for maintaining
        alignment between business operations and customer needs.
      </p>
      <ul>
        <li>Product Development</li>
        <li>Manufacturing/Production</li>
        <li>Marketing & Sales</li>
        <li>Customer Service/Support R&D</li>
        <li>Logistics & Supply Chain</li>
        <li>Quality Assurance & Control</li>
      </ul>

      <h1>Key Resources</h1>
      <p>
        Key resources are the essential assets and capabilities fundamental to delivering value to
        customers. They include tangible and intangible assets, such as technology, human capital,
        and intellectual property, which are essential for supporting key activities and achieving
        strategic objectives
      </p>

      <ul>
        <li>Human Capital</li>
        <li>Financial Resources</li>
        <li>Brand & Reputation</li>
        <li>Technology & Infrastructure</li>
        <li>Physical Assets</li>
      </ul>

      <h1>Key Partnerships and Suppliers</h1>
      <p>
        Key partnerships & suppliers are external entities critical to your business model. These
        collaborations provide access to resources, expertise, or distribution channels, while
        enhancing the capabilities and reach of your business to better serve customers and achieve
        strategic goals.
      </p>

      <ul>
        <li>Partnerships & Alliances</li>
        <li>Channels</li>
      </ul>

      <h1>Pricing Model</h1>
      <p>
        Your pricing model determines how revenue is generated, whether through one-time payments,
        subscription models, or other methods. It's crucial to decide this early as it's challenging
        to change later in development.
      </p>
    </div>
  );
}
