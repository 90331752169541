import { useState, useEffect } from "react";

const useWindowSize = (ref?: HTMLElement) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    const element = ref ? ref : window;

    element.addEventListener("resize", handleResize);

    return () => {
      element.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return windowSize;
};

export default useWindowSize;
