import { Tools } from "lib/data/Tools";
import { Link } from "react-router-dom";
import { TTool } from "types/JsonType";
import { SetDocumentTitle } from "utils/SetDocumentTitle";

function ToolTile({ tool }: { tool: TTool }) {
  return (
    <div className="p-2 h-100">
      <Link to={tool.url} className="text-decoration-none text-body h-100">
        <div className="border rounded bg-white h-100 shadow-sm-on-hover d-flex flex-column justify-content-between overflow-hidden">
          <div className="p-3">
            <h1 className="text-center">{tool.name}</h1>
            <p>{tool.summary}</p>
          </div>
          <img src={tool.imgSrc} className="w-100" alt="" />
        </div>
      </Link>
    </div>
  );
}

export default function ToolsHome() {
  SetDocumentTitle("Tools");
  return (
    <div className="w-100" style={{ minHeight: window.innerHeight - 100 }}>
      <div className="display-3 text-center my-4">Strategy Tools and Frameworks</div>
      <div className="container mb-5">
        <div className="row  ">
          {Object.values(Tools).map((tool, i) => (
            <div className="col-12 col-sm-6 col-md-4" key={i}>
              <ToolTile tool={tool} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
