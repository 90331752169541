import { TStudent } from "types/JsonType";

export const Students = [
  {
    firstName: "Corban",
    lastName: "Procuniar",
    imgSrc: require("lib/img/team/c_procuniar.jpg"),
    major: "Masters in Information Systems Management",
    role: "Web Developer",
    started: new Date("2023/10/28"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/corbanprocuniar",
  },
  {
    firstName: "Joseph",
    lastName: "Martin",
    imgSrc: require("lib/img/team/j_martin.jpg"),
    major: "Strategic Management",
    role: "Research Assistant",
    started: new Date("2024/01/01"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/josephjmartin/",
  },
  {
    firstName: "Tyler",
    lastName: "Hill",
    imgSrc: require("lib/img/team/t_hill.jpg"),
    major: "Strategic Management",
    role: "Research Assistant",
    started: new Date("2024/01/01"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/tylerhillconnect/",
  },
  {
    firstName: "Jonah",
    lastName: "Heninger",
    imgSrc: require("lib/img/team/j_heninger.jpg"),
    major: "Strategic Management",
    role: "Research Assistant",
    started: new Date("2024/05/03"),
    ended: null,
    linkedin: "https://www.linkedin.com/in/jonah-heninger-b22977292",
  },
] as const satisfies TStudent[];

export default Students as TStudent[];
