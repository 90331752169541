import { SetDocumentTitle } from "utils/SetDocumentTitle";

export default function Payment() {
  SetDocumentTitle("Payment");

  return (
    <div className="d-flex justify-content-center w-100 align-items-center">
      <div className="text-center">
        <div>This will be the payment page for users that are not BYU students.</div>
        <div>
          We are not taking payments at this time. Strategy Unlocked is only available for BYU
          students
        </div>
      </div>
    </div>
  );
}
