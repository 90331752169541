import { TSendOtpReq } from "@sharedTypes/req.type";
import { TErrorRes, TSendOtpRes } from "@sharedTypes/res.type";
import constants from "Constants";
import missingParametersError from "api/Error";
import axios from "axios";

export async function SendOtp(email: string, forgot = false) {
  const apiUrl = constants.apiUrl + "/email/sendotp";

  const body: TSendOtpReq = {
    email,
    forgot,
  };

  for (const [key, param] of Object.entries(body)) {
    if (param === undefined) return missingParametersError(key);
  }

  const data: TSendOtpRes | TErrorRes = (await axios.post(apiUrl, body)).data;

  return data;
}
