import React from "react";
import UniqueValueMap from "../maps/UniqueValueMap";

export default function UniqueValue() {
  return (
    <div>
      <p>
        Which of your customers' needs are currently unaddressed or only poorly addressed?
        Businesses that stand out from the competition understand what their customers are trying to
        accomplish, what about existing solutions frustrates customers, and how they can offer
        unique products and services to better meet these needs.
      </p>
      <UniqueValueMap />
    </div>
  );
}
