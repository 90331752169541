import { TVideo } from "types/JsonType";

const Videos = [
  {
    name: "Why International Business",
    summary:
      "How international business differs from domestic business. What factors you need to consider when doing business in a foreign environment",
    url: "https://youtu.be/lMdhfBQUhtI?si=sAvQjFL9mT2SzaFy",
    thumbnail: "https://img.youtube.com/vi/lMdhfBQUhtI/maxresdefault.jpg",
    type: "Video",
  },
  {
    name: "PEST Analysis",
    summary:
      "How to conduct a PEST analysis for your company. Professor Morris discusses how a PEST analysis can help you determine the risks and opportunities associated with entering a foreign market.",
    url: "https://www.youtube.com/watch?v=mCdcdf-b8AU",
    thumbnail: "https://img.youtube.com/vi/mCdcdf-b8AU/maxresdefault.jpg",
    type: "Video",
  },
  {
    name: "International Strategy",
    summary:
      "How do you win in a global market?  This video shows how to position your company in a global market, depending upon your objectives.",
    url: "https://www.youtube.com/watch?v=z7M1vQTvkx4",
    thumbnail: "https://img.youtube.com/vi/z7M1vQTvkx4/maxresdefault.jpg",
    type: "Video",
  },
  {
    name: "The Problem With Globalization",
    summary:
      "Globalization is not what most people expected. We thought this rising tide would lift all boats, but it hasn't. Globalization can be redirected to improve the lives of people all over the world. But it will require us to think differently.",
    url: "https://www.youtube.com/watch?v=uZXJYE0ZY0s",
    thumbnail: "https://img.youtube.com/vi/uZXJYE0ZY0s/maxresdefault.jpg",
    type: "Video",
  },
  {
    name: "Foreign Direct Investment Explained",
    summary:
      "Learn what foreign direct investment (FDI) is and how it affects you. We explore the investments that countries make in foreign countries and how the balance of FDI influences the economic well-being of a country. ",
    url: "https://www.youtube.com/watch?v=QI8zEyplFn4",
    thumbnail: "https://img.youtube.com/vi/QI8zEyplFn4/maxresdefault.jpg",
    type: "Video",
  },
  {
    name: "International Business Explained: Why Go International?",
    summary:
      "What is international business and why should companies go international? Learn from Shad Morris, Professor of International Business at Brigham Young University, as he teaches what benefits companies receive from international expansion.",
    url: "https://youtu.be/doN0_-I8ftA",
    thumbnail: "https://img.youtube.com/vi/doN0_-I8ftA/maxresdefault.jpg",
    type: "Video",
  },
] as const satisfies TVideo[];

export default Videos as TVideo[];
