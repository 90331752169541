import Image from "components/concepts/templates/Image";
import Definition from "../templates/Definition";
import ExampleContainer from "../templates/ExampleContainer";
import History from "../templates/History";
import Example from "../templates/Example";

const ThreeHorizonsImg = require("lib/img/concepts/Three_Horizons.png");
export default function ThreeHorizons() {
  return (
    <div>
      <Definition>
        <p>
          Many businesses struggle to know where to invest efforts in growth. Moreover, they
          struggle to maintain their core businesses while pursuing innovation. The Three Horizons
          Model effectively explains how to correctly prioritize and pursue incremental and more
          radical innovation, while maintaining the core business as a corporate strategy.
        </p>
        <p>
          Example - McDonald's recently launched a new brand "CosMc's" in the Beverage Sector.
          However, it also wants to maintain focus on its core business of Fast Food. The three
          Horizons framework would be ideal to help the company balance focus of its core business
          against its new venture.
        </p>
      </Definition>
      <Image src={ThreeHorizonsImg} alt="infographic describing the three horizons framework" />
      <ExampleContainer>
        <Example header="Unilever">
          Unilever, a global consumer goods company, used the framework to drive innovation across a
          wide brand portfolio. Horizon 1 included initiatives to enhance product formulations and
          packaging. Horizon 2 explored opportunities in personalized nutrition and sustainable
          packaging option. Horizon 3 included investments in a new business model, a possible
          subscription service for personal care products.
        </Example>
        <Example header="LEGO">
          The global toy manufacturer, engaged the model to revitalize its business and maintain its
          market position. Horizon 1 including strengthening their core product line by expanding
          the range of building sets and introducing new themes. Horizon 2 explored digital
          experiences including virtual and augmented reality experiences to enhance play. Finally
          Horizon 3 included new ideas such as educational robotics and sustainable materials
          development.
        </Example>
        <Example header="Toyota">
          The multinational automaker adopted the model to drive innovation throughout the changing
          automotive landscape. Horizon 1 initiatives included investments in enhancing fuel
          efficiency and performance of existing models. Horizon 2 pursued adjacent areas such as
          electric and autonomous vehicle technology. Horizon 3 included new manufacturing
          technology to expedite construction techniques.
        </Example>
      </ExampleContainer>
      <History>
        <p>
          The Model was originally published in the book, The Alchemy of Growth, written in 1999.
          The authors included three McKinsey directors who were focused on helping companies
          achieve sustained growth. The model was created to combat the typical product lifecycle of
          decline as companies mature. This model would theoretically incentivize innovation
          throughout the entire corporate lifecycle.
        </p>
        <p>
          Over time the model has seen alterations with the main development being that the model is
          no longer bounded by time. Instead of relegating each horizon to certain time periods, all
          three can occur simultaneously. This is necessary in the modern environment when business
          is rapidly changing. This means that horizon three may (of necessity) occur before horizon
          1. This change was publicized in 2019 in a Harvard Business Review Article by Steve Blank.
        </p>
        <p>
          Additionally, later articles communicated the 70/20/10 rule of prioritization. Simply
          stated, 70% of time and resources should be spent on the core business (Horizon 1), 20% of
          time and resources on emerging businesses (Horizon 2), and 10% on new businesses and
          technologies (Horizon 3). This rule enabled the model to be more effective as it
          demonstrated where to place optimal effort while operating the business for success.
        </p>
      </History>
      <hr />
      <p>Updated 5/1/2024</p>
    </div>
  );
}
